import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Button, Form, Modal, OverlayTrigger, Tooltip, Popover, Table } from "react-bootstrap";
import UserService from "../../services/UserService.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import logoSvg from './../../assets/images/logo.svg';

const serverConfig = require("../../config/server.js");

const UserRegistration = ({addCredits, org, sendIsNextVal, getCustAppCount}) => {
  console.log(addCredits);
  const searchParams = new URLSearchParams(window.location.search);

  const stripe = useStripe();
  const elements = useElements();

  const[isLoading, setIsLoading] = useState(false);
  
  const org_slug = addCredits ? org : searchParams.get("id");//if coming from addCredits button click
  console.log(org_slug);

  const [noOfApplications, setNoOfApplications] = useState(0);
  const [amount, setAmount] = useState(0);
  const [itemSlug, setItemSlug] = useState("");
  const [applicationNumList, setApplicationNumList] = useState([]);
  const [isNext, setIsNext] = useState(false);
  const [value, setValue] = useState(0);
  const min = 1;
  const max = 1000;

  const [couponCode, setCouponCode] = useState("");
  const [couponCodeValid, setCouponCodeValid] = useState(false);
  const [amountAfterDiscount, setAmountAfterDiscount] = useState(null);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  
  const pricingTable = (
    <Popover id="pricing-table-popover">
      <Popover.Header as="h6">Pricing Information</Popover.Header>
      <Popover.Body>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Company</th>
              <th>Amount ($)</th>
            </tr>
          </thead>
          <tbody>
            {applicationNumList.filter((el) => el.no_of_applications === 1 || el.no_of_applications === 5 || el.no_of_applications === 10 || el.no_of_applications === 20 || el.no_of_applications === 30 || el.no_of_applications === 40 || el.no_of_applications === 50).map((entry) => (
              <tr key={entry.no_of_applications}>
                <td>{entry.label}</td>
                <td>{entry.amount}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Popover.Body>
    </Popover>
  );

  const timeoutIdRef = useRef(null); // Ref to store the timeout ID

  const handleApplicationsNumberChange = (e) => {
    const newValue = parseInt(e.target.value, 10);

    if (!isNaN(newValue)) {
      const clampedValue = Math.min(Math.max(newValue, min), max);
      setValue(clampedValue);

      if (clampedValue <= 50) {
        const matchingEntry = applicationNumList.find(
          (entry) => entry.no_of_applications === clampedValue
        );

        if (matchingEntry) {
          setItemSlug(matchingEntry.slug);
          setAmount(matchingEntry.amount);
        }
      } else {
      
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
        }

        timeoutIdRef.current = setTimeout(() => {
          handleLogEnquiry(clampedValue); 
        }, 2000);
      }
    }
  };

  const handleLogEnquiry = (credits) => {
    axios
      .post(serverConfig.api_base_url + "log_enquiry", {
        cust_email: inputs?.email,
        cust_name: inputs?.name,
        credits: credits,
      })
      .then((response) => {
        if (response.data.status) {
          console.log("API call successful:", response.data);
        }
      })
      .catch((error) => {
        console.error("API error:", error);
      });
  };

  useEffect(() => {
    console.log(applicationNumList);
    if(applicationNumList.length > 0){
      console.log(applicationNumList[0].no_of_applications, applicationNumList[0].amount);
      setNoOfApplications(applicationNumList[0].no_of_applications);
      setAmount(applicationNumList[0].amount);
      setItemSlug(applicationNumList[0].slug);
      setValue(applicationNumList[0]?.no_of_applications);
    }
  }, [applicationNumList]);

  const navigate = useNavigate();

  const [inputs, setInputs] = useState({allowPromoCodes: "false"});
  const [formIsDisabled, setFormIsDisabled] = useState(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');//hubspot tracking code
    script.src = "https://js.hs-scripts.com/178283.js";
    script.type = "text/javascript";
    script.id="hs-script-loader"
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    axios.post(serverConfig.api_base_url + "org_credit_plan_get", {org: org_slug})
    .then(response=>{
      console.log('org_credit_plan_get', response);
      if(response.data.status === 200) {
        if(response.data.items !== null){
          setApplicationNumList(response.data.items);
        }
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }, []);

  const checkCouponCode = async (e) => {
    setIsLoading(true);
    console.log(couponCode);

    try {
      const response = await fetch(
        serverConfig.fapi_base_url + "validate_promo_code",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            promo_code: couponCode,
            paymentItemSlug: itemSlug,
            org_slug: org_slug
          }),
        }
      );

      const data = await response.json();
      console.log("validate_promo_code response data", data);

      console.log("validate_promo_code response", response);
      if(data.status === "200") {
        setCouponCodeValid(true);
        console.log(data.final_amount);
        setAmountAfterDiscount(data.final_amount);
        setError("");
      } else if(data.status === 400){
        setCouponCodeValid(false);
        setError(<div className="txt-red my-2">{data.message}</div>);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error handling request:', error);
      setError(<div className="txt-red my-2">An unexpected error occurred.</div>);
      setIsLoading(false);
    }
  }

  const handleProceedToPayment = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    console.log("noOfApplications-", noOfApplications, "amount-", amount, "itemSlug-", itemSlug, "org_slug-", org_slug, "email-", inputs.email, "addCredits-", addCredits, "allow_promotion_codes", inputs.allowPromoCodes);
    console.log({
      email: addCredits ? UserService.getUsername() : inputs.email,
      name: addCredits ? UserService.getWelcomeName() : inputs.name,
      promo_code: couponCode,
      paymentItemSlug: itemSlug,
      org_slug: org_slug
    });

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      const response = await axios.post(serverConfig.fapi_base_url + "create_payment_intent", {
        email: addCredits ? UserService.getUsername() : inputs.email,
        name: addCredits ? UserService.getWelcomeName() : inputs.name,
        //address: {line1: "house no. 1", city:"Bangalore", state: "Karnataka", postal_code: "560078", country: "IN"},//comment this for US stripe account setup
        promo_code: couponCode,
        paymentItemSlug: itemSlug,
        org_slug: org_slug
      });

      if (response.data.clientSecret) {
        const cardElement = elements.getElement(CardElement);

        const result = await stripe.confirmCardPayment(response.data.clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: { name: inputs.name, email: inputs.email },
          },
        });

        if (result.error) {
          setError(<div className="txt-red my-2">{result.error.message}</div>);

          axios({
            method: 'post',
            url: serverConfig.fapi_base_url + "update_payment_status",
            data: JSON.stringify({
              clientSecret: response.data.clientSecret,
              payment_status: "failed",
              item_slug: itemSlug,
              org_slug: org_slug
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
          })
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.error(error);
          });
        } else {
          if(addCredits){
            setSuccessMessage(<div className="txt-green my-2">Payment successful!</div>);
          } else if(!addCredits){
            setSuccessMessage(<div className="txt-green my-2">Payment successful! Please check your email to proceed with the registration process.</div>);
            setPaymentSuccessful(true);
          }
          setFormIsDisabled(true);
          axios({
            method: 'post',
            url: serverConfig.fapi_base_url + "update_payment_status",
            data: JSON.stringify({
              clientSecret: response.data.clientSecret,
              payment_status: "success",
              item_slug: itemSlug,
              org_slug: org_slug
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
          })
          .then(response => {
            console.log(response);
            if(addCredits){
              getCustAppCount();
            }
          })
          .catch(error => {
            console.error(error);
          });
        }
      } else if (response.data.message) {
        // For free transactions
        //setSuccessMessage(<div className="txt-green my-2">{response.data.message}</div>); 
        if(addCredits){
          setSuccessMessage(<div className="txt-green my-2">Payment successful!</div>);
          getCustAppCount();
        } else if(!addCredits){
          setSuccessMessage(<div className="txt-green my-2">Payment successful! Please check your email to proceed with the registration process.</div>);
          setPaymentSuccessful(true);
        }
        setFormIsDisabled(true);
        //update_payment_status at this point happens from api code side
      }
    } catch (err) {
      setError(<div className="txt-red my-2">{err.response?.data?.detail || "An error occurred."}</div>);
    }

    setIsProcessing(false);
  }

  const handleNext = () => {
    handleLogEnquiry(0);
    setIsNext(true);
    sendIsNextVal(true);
    window.scrollTo(0, 0);
  }

  return (
    <div className={addCredits ? "border p-4 form-container" : ""}>
      {isLoading && (<div id='spinner'></div>)}
      {addCredits && <div className="text-center">
        <img
          src={logoSvg}
          alt="Logo"
          className="img-fluid" width="300"
        />
        <h1 style={{fontSize: "18px"}} className="mb-5 mt-3">Manatoko BOIR Purchase Plan</h1>
      </div>}
      <Form onSubmit={handleProceedToPayment}>
        <fieldset disabled={formIsDisabled}>
          {isNext === false && <>
            <h5>Authorized Signer Details</h5>
            <Form.Group className="mb-2">
              <Form.Label className='mb-0'>
                Name of Authorized Signer <span className='txt-red'>*</span>
                <OverlayTrigger placement="top" overlay={<Tooltip>This is the person who is Authorized Signer of the company</Tooltip>}>
                  <span className="info"><i>i</i></span>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control type="text" value={addCredits ? UserService.getWelcomeName() : inputs.name} name="name" onChange={(e) => setInputs({...inputs, name: e.target.value})} required readOnly={addCredits} />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className='mb-0'>Email of Authorized Signer <span className='txt-red'>*</span></Form.Label>
              <Form.Control type="email" value={addCredits ? UserService.getUsername() : inputs.email} name="email" onChange={(e) => setInputs({...inputs, email: e.target.value})} required readOnly={addCredits} />
            </Form.Group>
            <Button variant="primary" className="w-100 mt-2" id="submit" disabled={addCredits ? false : !(inputs.email && inputs.name)} onClick={handleNext}>Next</Button>
            {!addCredits && <div className='redirect-signup justify-content-center'>
              <div className='redirect-text'>Already Registered?</div> <button onClick={()=>{ navigate("/customer")} } className='login-btn'>LOG IN</button>
            </div>}
          </>}

          {isNext === true && <>
            <Form.Group className="mb-2">
              <h6>For how many companies do you need to file a BOIR?</h6>
              <Form.Label>
                <i>
                  You will need one credit for each company. For example, if you have
                  just 1 company, you will need 1 credit, and if you have 10 companies,
                  you will need 10 credits.
                  <br />
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="right"
                    overlay={pricingTable}
                  >
                    <span
                      style={{
                        textDecoration: "underline",
                        color: "var(--color3)",
                        cursor: "pointer",
                      }}
                    >
                      See pricing information
                    </span>
                  </OverlayTrigger>
                </i>
              </Form.Label>
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  justifyContent: "space-between",
                }}
                className="flex flex-row"
              >
                <div
                  style={{ display: "flex", marginTop: "10px" }}
                  className="flex flex-row"
                >
                  <Form.Label
                    style={{
                      marginRight: "10px",
                      marginTop: "9px",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    No. of credits
                  </Form.Label>
                  <Form.Control
                    type="number"
                    defaultValue={value}
                    onChange={handleApplicationsNumberChange}
                    min={min}
                    max={max}
                    style={{
                      width: "60px",
                      padding: "8px",
                      textAlign: "center",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                </div>
                {value <= 50 ? (
                  <div
                    style={{ display: "flex", marginTop: "10px" }}
                    className="flex flex-row"
                  >
                    <Form.Label
                      style={{
                        marginRight: "10px",
                        marginTop: "9px",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      Total Amount: {couponCodeValid ? <><s>$ {amount}</s> $ {amountAfterDiscount}</> : <>$ {amount}</>}
                    </Form.Label>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {value <= 50 ? (<>
                <Form.Group className="mt-2 mb-3">
                  <Form.Check type="checkbox" id="allowPromoCodes" name="allowPromoCodes" 
                    label={<>I have a coupon code and agree to finish a brief survey after my BOI submission {/* <OverlayTrigger placement="top" overlay={<Tooltip>Agree to User survey (discounts will work only if the user agrees to take the survey at the end)</Tooltip>}>
                    <span className="info"><i>i</i></span>
                  </OverlayTrigger> */}</>} 
                    onChange={(e) => {
                    if(e.target.checked){
                      console.log("true");
                      setInputs({...inputs, allowPromoCodes: "true"});
                      setCouponCode(""); setCouponCodeValid(false); setAmountAfterDiscount(null);
                    } else {
                      console.log("false");
                      setInputs({...inputs, allowPromoCodes: "false"});
                      setCouponCode(""); setCouponCodeValid(false); setAmountAfterDiscount(null);
                    }
                  }}
                />
                </Form.Group>

                {inputs.allowPromoCodes === "true" && <Form.Group className="mb-2">
                  <Form.Label className='mb-0'>Coupon code</Form.Label>
                  <Row className='align-items-center'>
                    <Col>
                      <Form.Control
                        type="text"
                        name="coupon_code"
                        className='custom-input'
                        value={couponCode}
                        onChange={(e) => {setCouponCodeValid(false); setAmountAfterDiscount(null); setCouponCode(e.target.value.toUpperCase());}}
                      />
                    </Col>
                    <Col xs="auto"><Button type="button" variant="transparent" className='p-2' onClick={checkCouponCode} disabled={couponCode === ""}>Apply</Button></Col>
                  </Row>
                </Form.Group>}

                <CardElement id="payment-element" />
                
                {/* <Payment orgSlug={org_slug} isAuthorizedSigner={isAuthorizedSigner} setIsAuthorizedSigner={setIsAuthorizedSigner} setApplicationNumList={setApplicationNumList} noOfApplications={noOfApplications} amount={amount} currency={currency} inputs={inputs} couponCode={couponCode} couponCodeValid={couponCodeValid} amountAfterDiscount={amountAfterDiscount} /> */}
                <Button
                  type="submit"
                  variant="primary"
                  className="w-100 mt-4"
                  id="submit"
                  disabled={!stripe}
                >
                  {isProcessing ? "Processing ... " : "Submit Payment"}
                </Button>
              </>) : (
                <div
                  style={{
                    marginTop: "20px",
                    fontSize: "13px",
                    fontWeight: "600",
                    color: "var(--color3)",
                  }}
                >
                  If you need more than 50 credits, please reach out to us at
                  <br />
                  <i
                    className="fas fa-envelope"
                    style={{ marginRight: "8px" }}
                    aria-hidden="true"
                  ></i>
                  boir@amberoon.com
                  <br />
                </div>
              )}
            </Form.Group>
          </>}
        </fieldset>
      </Form>

      {error && error}
      {successMessage && successMessage}

      <Modal show={paymentSuccessful} onHide={() => setPaymentSuccessful(false)} centered keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Payment successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {successMessage && successMessage}
        </Modal.Body>
        <Modal.Footer><Button variant="transparent" type="button" onClick={() => setPaymentSuccessful(false)}>Okay</Button></Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserRegistration;
